import React from 'react';
import Svg from '../Svg/Svg';

const Plus = ({ color, size, style, onClick, className, variant }) => (
  <Svg
    className={className}
    style={style}
    onClick={onClick}
    size={size}
    color={color}
    variant={variant}
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
  >
    <g>
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M 7 0 h 7 v 7 h 7 v 7 h -7 v 7 h -7 v -7 h -7 v -7 h 7"
      />
    </g>
  </Svg>
);

export default Plus;
