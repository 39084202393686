import React from 'react';
import {
  responsiveFontSizes,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

export const themeObject = {
  shadows: new Array(25).fill('none'),
  palette: {
    primary: {
      main: '#F3DBC7',
    },
    secondary: {
      main: '#ECB595',
    },
  },
  typography: {
    fontFamily: ['Hind', 'sans-serif'].join(','),
    fontSize: 14,
    button: {
      textTransform: 'none',
    },
  },
  spacing: factor => `${0.25 * factor}rem`,
  overrides: {
    MuiButton: {
      root: {
        fontSize: '1.1rem',
      },
      label: {
        letterSpacing: 0,
      },
    },
    MuiContainer: {
      maxWidthLg: {
        maxWidth: '1248px !important',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#F3DBC7',
        },
      },
    },
  },
};

const theme = createMuiTheme(themeObject);

export const MuiTheme = responsiveFontSizes(theme);

const SMPSubmissionThemeProvider = ({ children }) => (
  <ThemeProvider theme={MuiTheme}>
    <MuiThemeProvider theme={MuiTheme}>{children}</MuiThemeProvider>
  </ThemeProvider>
);

export default SMPSubmissionThemeProvider;
