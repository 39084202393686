import React from 'react';
import Svg from '../Svg/Svg';

const Sort = ({ color, size, style, onClick, className, variant }) => (
  <Svg
    className={className}
    style={style}
    onClick={onClick}
    size={size}
    color={color}
    variant={variant}
    width="1em"
    height="0.9em"
    viewBox="0 0 14 11"
  >
    <g
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g transform="translate(-110.000000, -1131.000000)">
        <g transform="translate(111.000000, 1132.000000)">
          <path d="M0,0.409090909 L11.6514946,0.409090909" />
          <path d="M0,4.5 L11.6514946,4.5" />
          <path d="M0,8.59090909 L11.6514946,8.59090909" />
        </g>
      </g>
    </g>
  </Svg>
);

export default Sort;
