/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  cssOutlinedInput: {
    backgroundColor: '#FFFFFF',
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
});

const BaseInput = ({ InputProps, error, ...props }) => {
  const classes = useStyles();

  return (
    <MuiTextField
      className={classes.textField}
      margin="normal"
      variant="outlined"
      InputLabelProps={{
        classes: {},
      }}
      InputProps={{
        autoComplete: 'off',
        classes: {
          root: classes.cssOutlinedInput,
          notchedOutline: classes.notchedOutline,
        },
        ...InputProps,
      }}
      error={!!error}
      {...props}
    />
  );
};

export default BaseInput;
