import styled from 'styled-components';
import TextField from '../textfield';

const TextArea = styled(TextField)`
  & textarea {
    resize: vertical;
  }
`;

TextArea.defaultProps = {
  multiline: true,
};

export default TextArea;
