import React from 'react';
import styled from 'styled-components';

// Use width and height as proportions for svg
const Svg = styled.svg`
  width: ${({ width }) => width || '1em'};
  height: ${({ height }) => height || '1em'};
`;

// Relay on viewbox width and height as proportions for svg
const Wrapper = styled.div`
  && {
    font-size: ${({ size }) => size || 'inherit'};
    color: ${({ color }) => color || 'currentColor'};
    display: inline-flex;
    flex: 0 1 auto;
    justify-content: center;
    align-items: center;
    padding: 0;

    ${({ variant }) =>
      variant === 'square' &&
      `
        width: 1em;
        height: 1em;
    `}

    ${({ variant }) =>
      variant === 'circle' &&
      `
        border-radius: 50%;
        width: 1em;
        height: 1em;
        border: 0.03em solid currentColor;

        ${Svg} {
          width: 0.5em;
          height: 0.5em;
        }
    `}
  }
`;

const Icon = ({
  width,
  height,
  viewBox,
  color,
  size,
  style,
  onClick,
  className,
  variant = 'normal',
  children,
  transform,
}) => (
  <Wrapper
    variant={variant}
    color={color}
    size={size}
    className={className}
    onClick={onClick}
  >
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      width={width}
      height={height}
      viewBox={viewBox}
      transform={transform}
    >
      {children}
    </Svg>
  </Wrapper>
);
export default Icon;
